import { StoreColours } from "@/components/builder/ActionPanel"
import PickupAndDelivery from "@/components/builder/ActionPanel/Settings/PickupAndDelivery"
import SettingsMenu from "@/components/builder/ActionPanel/Settings/SettingsMenu"
import StorePolicy from "@/components/builder/ActionPanel/Settings/StorePolicy"
import { settingsUIAtom, SettingsUIState } from "@/state"
import { useAtomValue } from "jotai"
import React from "react"

export default function Settings() {
	const { uiState } = useAtomValue(settingsUIAtom)

	let component

	switch (uiState) {
		case SettingsUIState.PickupAndDelivery:
			component = PickupAndDelivery
			break
		case SettingsUIState.ColourStyles:
			component = StoreColours
			break
		case SettingsUIState.StorePolicies:
			component = StorePolicy
			break
		default:
			component = SettingsMenu
			break
	}

	return React.createElement(component)
}

import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { TransparentDivider } from "@/components/TransparentDivider"
import { WebstoreBusinessDay } from "@/enums"
import { businessHoursAtom } from "@/state"
import { BusinessHoursSlot } from "@/types"
import { button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControlLabel from "@mui/material/FormControlLabel"
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
import { SxProps } from "@mui/system"
import { Plus, TrashSimple } from "@phosphor-icons/react"
import { useAtom, useAtomValue } from "jotai"
import React from "react"

const maxCharacterCount = 100
const blurMutationFns = [trim]
const changeMutationFns = [getMaxCharacterMutationFn(maxCharacterCount)]

const ofMaxCharactersText = (currentLength: number, maxLength: number) =>
	`Max characters ${currentLength}/${maxLength}, letters and numbers only`

export function BusinessHours() {
	const [businessHours, setBusinessHours] = useAtom(businessHoursAtom)

	const handleDaysChange = (id: string, day: string) => {
		const daysUpdated = (hours: BusinessHoursSlot) =>
			hours.days.includes(day) ? hours.days.filter((_day) => _day !== day) : [...hours.days, day]

		setBusinessHours((prevBusinessHours) =>
			prevBusinessHours.map((hours) =>
				hours.id === id
					? {
							...hours,
							days: daysUpdated(hours),
						}
					: hours,
			),
		)
	}

	const handleOpenChange = (id: string) => {
		setBusinessHours((prevBusinessHours) =>
			prevBusinessHours.map((hours) =>
				hours.id === id ? { ...hours, open: !hours.open, openingHours: hours.open ? hours.openingHours : "" } : hours,
			),
		)
	}

	const handleOpeningHoursChange = (id: string, openingHours: string) => {
		setBusinessHours((prevBusinessHours) =>
			prevBusinessHours.map((hours) => (hours.id === id ? { ...hours, openingHours } : hours)),
		)
	}

	const canAddSlot = businessHours.value.reduce((acc, { days }) => acc + days.length, 0) < Object.keys(WebstoreBusinessDay).length

	const handleDelete = (slot: BusinessHoursSlot) =>
		setBusinessHours((prevBusinessHours) => prevBusinessHours.filter((hours) => hours.id !== slot.id))

	return (
		<Stack padding="1rem">
			{businessHours.value.map((slot) => (
				<BusinessHoursForm
					key={slot.id}
					id={slot.id}
					days={slot.days}
					onDaysChange={(day) => handleDaysChange(slot.id, day)}
					open={slot.open}
					onOpenChange={() => handleOpenChange(slot.id)}
					openingHours={slot.openingHours}
					onOpeningHoursChange={(openingHours) => handleOpeningHoursChange(slot.id, openingHours)}
					onDelete={() => handleDelete(slot)}
				/>
			))}
			{canAddSlot && (
				<Button
					sx={{ justifyContent: "flex-start", alignItems: "flex-start" }}
					color="secondary"
					variant="text"
					startIcon={<Plus size={18} />}
					onClick={() => {
						setBusinessHours((prevBusinessHours) => [
							...prevBusinessHours,
							{
								id: window.crypto.randomUUID(),
								days: [],
								open: true,
								openingHours: "",
							},
						])
					}}
				>
					<Typography sx={button.small} textTransform="none" color="secondary">
						Add another slot
					</Typography>
				</Button>
			)}
		</Stack>
	)
}

type BusinessHoursFormProps = BusinessHoursSlot & {
	onDaysChange: (day: string) => void
	onOpenChange: () => void
	onOpeningHoursChange: (openingHours: string) => void
	onDelete: () => void
}

function BusinessHoursForm({
	id,
	open,
	openingHours,
	onDaysChange,
	onOpenChange,
	onOpeningHoursChange,
	onDelete,
}: BusinessHoursFormProps) {
	const { value } = useAtomValue(businessHoursAtom)

	const a = value.reduce((acc, { id, days }) => [...acc, ...days.map((day) => [day, id])], [] as string[][])
	const businessHoursOwnerMap = new Map()
	a.forEach(([key, value]) => businessHoursOwnerMap.set(key, value))

	const getAvatarStyles = (day: string): SxProps => {
		const slotId = businessHoursOwnerMap.get(day)

		if (slotId === id) return { bgcolor: "teal", border: "1px teal solid" }

		return {
			bgcolor: slotId ? "lightgrey" : "white",
			border: slotId ? "1px white solid" : "1px lightgrey solid",
		}
	}

	const getTypographyStyles = (day: string): SxProps => {
		const slotId = businessHoursOwnerMap.get(day)

		if (slotId === id) return { color: "white" }

		return { color: slotId ? "grey" : "text.primary" }
	}

	const handleDaysChange = (day: string) => {
		if (!(businessHoursOwnerMap.get(day) === id || !businessHoursOwnerMap.get(day))) {
			return
		}

		onDaysChange(day)
	}

	return (
		<Stack>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Typography variant="subtitle2">Days</Typography>
				<Box sx={{ cursor: "pointer" }}>
					<TrashSimple size={24} onClick={onDelete} />
				</Box>
			</Stack>
			<TransparentDivider height="1rem" />

			<Stack direction="row" gap=".5rem">
				{Object.keys(WebstoreBusinessDay).map((day) => (
					<Avatar
						key={day}
						variant="circular"
						sx={{
							width: 28,
							height: 28,
							cursor: businessHoursOwnerMap.get(day) === id || !businessHoursOwnerMap.get(day) ? "pointer" : "default",
							...getAvatarStyles(day),
						}}
						onClick={() => handleDaysChange(day)}
					>
						<Typography variant="caption" sx={getTypographyStyles(day)}>
							{day.at(0)}
						</Typography>
					</Avatar>
				))}
			</Stack>
			<TransparentDivider height="1rem" />

			<FormControlLabel
				control={<Switch color="secondary" checked={open} onChange={onOpenChange} inputProps={{ "aria-label": "controlled" }} />}
				label={open ? "Open" : "Closed"}
			/>
			<TransparentDivider height="1rem" />

			{open && (
				<>
					<TextField
						name="time"
						color="secondary"
						variant="outlined"
						label="Opening and closing times"
						helperText={ofMaxCharactersText(openingHours.length, maxCharacterCount)}
						onChange={(e) => onOpeningHoursChange(e.target.value)}
						value={openingHours}
						fullWidth
						placeholder="09:00 AM - 05:00 PM"
						size="medium"
						blurMutationFns={blurMutationFns}
						changeMutationFns={changeMutationFns}
					/>
					<TransparentDivider height="2.5rem" />
				</>
			)}
		</Stack>
	)
}

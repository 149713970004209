import { TransparentDivider } from "@/components/TransparentDivider"
import { WebstoreDocumentKey } from "@/enums"
import { useGetWebstorePolicyConfig } from "@/hooks"
import { ViewedStorePolicies, viewedStorePoliciesAtom, webstorePolicyDocumentAtom, webstorePolicyUIStateAtom } from "@/state"
import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { CheckCircle, Circle } from "@phosphor-icons/react"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { useHydrateAtoms } from "jotai/utils"
import moment from "moment"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

const AcceptList: Array<{ key: WebstoreDocumentKey; label: string }> = [
	{ key: WebstoreDocumentKey.PAIA_MANUAL, label: "PAIA manual*" },
	{ key: WebstoreDocumentKey.PRIVACY_POLICY, label: "Privacy policy*" },
	{ key: WebstoreDocumentKey.RETURNS_REFUNDS_POLICY, label: "Returns & refunds policy*" },
	{ key: WebstoreDocumentKey.DELIVERY_POLICY, label: "Delivery/collections policy*" },
	{ key: WebstoreDocumentKey.ACCEPT_TERMS, label: "Accept terms*" },
]

const StorePolicyDocumentNav = () => {
	const { webstorePoliciesViewedConfigDraft } = useGetWebstorePolicyConfig()
	const [acceptedList, setAcceptedList] = useState<Array<{ key: WebstoreDocumentKey; label: string }>>([])
	const [acceptedListCount, setAcceptedListCount] = useState<number>(0)
	const setWebstorePolicyUIStateAtom = useSetAtom(webstorePolicyUIStateAtom)
	const [webstorePolicyDocument, setWebstorePolicyDocument] = useAtom(webstorePolicyDocumentAtom)
	const viewedStorePolicies = useAtomValue(viewedStorePoliciesAtom)
	const acceptedAt = webstorePoliciesViewedConfigDraft?.acceptedAt
	const paiaManual = webstorePoliciesViewedConfigDraft?.storePolicies?.paiaManual
	const privacyPolicy = webstorePoliciesViewedConfigDraft?.storePolicies?.privacyPolicy
	const deliveryPolicy = webstorePoliciesViewedConfigDraft?.storePolicies?.deliveryPolicy
	const returnsRefundsPolicy = webstorePoliciesViewedConfigDraft?.storePolicies?.returnsRefundsPolicy

	const policiesViewedConfig: ViewedStorePolicies = {
		acceptedAt: acceptedAt || viewedStorePolicies.acceptedAt,
		paiaManual: paiaManual || viewedStorePolicies.paiaManual,
		privacyPolicy: privacyPolicy || viewedStorePolicies.privacyPolicy,
		deliveryPolicy: deliveryPolicy || viewedStorePolicies.deliveryPolicy,
		returnsRefundsPolicy: returnsRefundsPolicy || viewedStorePolicies.returnsRefundsPolicy,
	}

	useHydrateAtoms([[viewedStorePoliciesAtom, policiesViewedConfig]])

	const checkPolicyTnCViewed = (policyKey: string): boolean => {
		switch (policyKey) {
			case WebstoreDocumentKey?.PAIA_MANUAL:
				return policiesViewedConfig.paiaManual
			case WebstoreDocumentKey?.PRIVACY_POLICY:
				return policiesViewedConfig.privacyPolicy
			case WebstoreDocumentKey?.RETURNS_REFUNDS_POLICY:
				return policiesViewedConfig.returnsRefundsPolicy
			case WebstoreDocumentKey?.DELIVERY_POLICY:
				return policiesViewedConfig.deliveryPolicy
			case WebstoreDocumentKey?.ACCEPT_TERMS:
				return !!policiesViewedConfig.acceptedAt
			default:
				return false
		}
	}

	const workoutCompleteSteps = (): number => {
		let complete: number = 0

		if (policiesViewedConfig?.acceptedAt) {
			return 5
		}

		if (policiesViewedConfig.paiaManual) {
			complete++
		}

		if (policiesViewedConfig.privacyPolicy) {
			complete++
		}

		if (policiesViewedConfig.returnsRefundsPolicy) {
			complete++
		}

		if (policiesViewedConfig.deliveryPolicy) {
			complete++
		}

		return complete
	}

	const getMenuItemFontWeight = (itemKey: WebstoreDocumentKey): string => {
		if (!!viewedStorePolicies.acceptedAt && itemKey === WebstoreDocumentKey.ACCEPT_TERMS) {
			return "500"
		}

		return "400"
	}

	const getMenuItemTextColor = (itemKey: WebstoreDocumentKey): string => {
		if (!!viewedStorePolicies.acceptedAt && itemKey === WebstoreDocumentKey.ACCEPT_TERMS) {
			return palette.success.main
		}

		return palette.text.primary
	}

	useEffect(() => {
		setAcceptedList(AcceptList)
		setWebstorePolicyUIStateAtom(true)

		return () => {
			setWebstorePolicyUIStateAtom(false)
		}
	}, [AcceptList])

	useEffect(() => {
		setAcceptedListCount(workoutCompleteSteps())
	}, [viewedStorePolicies])

	return (
		<Stack>
			<Box paddingX={"0rem"}>
				<Alert severity="info" variant="standard" icon={false} sx={{ backgroundColor: "#E5F6FD" }}>
					<Typography variant="body2">
						To sell online and develop trust with your customers while operating using the Webstore, we require you to accept
						policies to protect all parties involved.
					</Typography>
					<TransparentDivider sx={{ height: "1rem" }} />
					<Typography variant="body2" sx={{ fontWeight: "700" }}>
						Please note: you will only be able to accept terms once you have opened all four policies.
					</Typography>
				</Alert>
			</Box>
			<Typography
				variant="body2"
				sx={{
					paddingBottom: "1.5rem",
					paddingTop: "0.5rem",
					fontStyle: "italic",
					color: palette.text.secondary,
				}}
			>
				{acceptedListCount}/{acceptedList.length} completed
			</Typography>
			<Box paddingX={"0rem"}>
				{AcceptList.map((item) => (
					<div key={uuidv4()} hidden={!!viewedStorePolicies.acceptedAt && item.key === WebstoreDocumentKey.ACCEPT_TERMS}>
						<MenuItem
							divider
							sx={{
								paddingX: "0px",
								"& .MuiTypography-root": {
									fontWeight: item.key === webstorePolicyDocument ? "700" : getMenuItemFontWeight(item.key),
									color:
										acceptedListCount < 4 && item.key === WebstoreDocumentKey.ACCEPT_TERMS
											? palette.text.disabled
											: getMenuItemTextColor(item.key),
								},
							}}
							disabled={acceptedListCount < 4 && item.key === WebstoreDocumentKey.ACCEPT_TERMS}
							onClick={() => {
								switch (item.key) {
									case WebstoreDocumentKey?.PAIA_MANUAL:
										setWebstorePolicyDocument(WebstoreDocumentKey.PAIA_MANUAL)
										break
									case WebstoreDocumentKey.PRIVACY_POLICY:
										setWebstorePolicyDocument(WebstoreDocumentKey.PRIVACY_POLICY)
										break
									case WebstoreDocumentKey.RETURNS_REFUNDS_POLICY:
										setWebstorePolicyDocument(WebstoreDocumentKey.RETURNS_REFUNDS_POLICY)
										break
									case WebstoreDocumentKey.DELIVERY_POLICY:
										setWebstorePolicyDocument(WebstoreDocumentKey.DELIVERY_POLICY)
										break
									case WebstoreDocumentKey.ACCEPT_TERMS:
										setWebstorePolicyDocument(WebstoreDocumentKey.ACCEPT_TERMS)
										break
									default:
										break
								}
							}}
						>
							<ListItemIcon>
								{(checkPolicyTnCViewed(item.key) || item.key === webstorePolicyDocument) &&
								item.key !== WebstoreDocumentKey.ACCEPT_TERMS ? (
									<CheckCircle size={24} color={palette.success.main} />
								) : (
									<Circle size={24} />
								)}
							</ListItemIcon>
							<Box>
								<ListItemText
									primary={item.label}
									sx={{
										textWrap: "wrap",
										"& .MuiTypography-root": {
											opacity: item.key === WebstoreDocumentKey.ACCEPT_TERMS && acceptedListCount < 4 ? "0.38" : "0.87",
										},
									}}
								/>
							</Box>
						</MenuItem>
					</div>
				))}
				{policiesViewedConfig.acceptedAt && (
					<Stack direction="row" marginTop="0.375rem" gap=".8rem">
						<CheckCircle size={24} color={palette.success.main} />
						<Stack>
							<Typography color={palette.success.main} fontWeight={500}>
								Policy accepted
							</Typography>
							<Typography color={palette.text.secondary} variant="caption" sx={{ opacity: "0.6" }}>
								{moment(policiesViewedConfig.acceptedAt).format("ddd, DD MMM YYYY")}
							</Typography>
						</Stack>
					</Stack>
				)}
			</Box>
		</Stack>
	)
}

export default StorePolicyDocumentNav

import { WebstoreTheme } from "@/enums"
import { colourThemeAtom } from "@/state"
import { teal, green, orange, defaultTheme, blue, purple, darkTheme } from "@/templateThemes"
import { ThemeProvider } from "@mui/material/styles"
import { useAtomValue } from "jotai"

type Props = {
	themeName?: WebstoreTheme
}

const getTheme = (themeName: WebstoreTheme) => {
	switch (themeName) {
		case WebstoreTheme.Blue:
			return blue
		case WebstoreTheme.Teal:
			return teal
		case WebstoreTheme.Orange:
			return orange
		case WebstoreTheme.Green:
			return green
		case WebstoreTheme.Purple:
			return purple
		case WebstoreTheme.DarkTheme:
			return darkTheme
		default:
			return defaultTheme
	}
}

/**
 * The themeName prop should be used when rendering the template to the public.\
 * Defaults to the builder selected theme if no themeName is provided.
 * @param props
 * @returns
 */
const TemplateComponentThemeProvider = (props: React.PropsWithChildren<Props>) => {
	const builderSelectedTheme = useAtomValue(colourThemeAtom)

	const theme = props.themeName ? props.themeName : builderSelectedTheme ?? WebstoreTheme.DefaultTheme

	return <ThemeProvider theme={getTheme(theme)}>{props.children}</ThemeProvider>
}

export default TemplateComponentThemeProvider

import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { TransparentDivider } from "@/components/TransparentDivider"
import { builderValuesAtom } from "@/state"
import { Hero } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue } from "jotai"
import React from "react"

const headerMaxCharacterCount = 60
const subheadingMaxCharacterCount = 100

const ofMaxCharactersText = (currentLength: number, maxLength: number) => `Max characters ${currentLength}/${maxLength}`

const blurMutationFns = [trim]
const headerChangeMutationFns = [getMaxCharacterMutationFn(headerMaxCharacterCount)]
const subheadingChangeMutationFns = [getMaxCharacterMutationFn(subheadingMaxCharacterCount)]

export const HeroContent = () => {
	const { fieldErrors, handleOnChange, values } = useAtomValue(builderValuesAtom)

	const hero = values.bannerText as Hero

	const errorMessage = fieldErrors.bannerText?.message

	const fieldHasError = (field: "header" | "subheading") => fieldErrors.bannerText?.path === field

	const getErrorMessage = (field: "header" | "subheading"): string | undefined =>
		fieldHasError(field) ? errorMessage : undefined

	return (
		<Stack p="1rem">
			<TextField
				name="header"
				variant="outlined"
				color="secondary"
				label="Header text (optional)"
				helperText={getErrorMessage("header") ?? ofMaxCharactersText(hero.header?.length ?? 0, headerMaxCharacterCount)}
				onChange={(e) => handleOnChange("bannerText")({ ...hero, header: e.target.value })}
				value={hero.header}
				fullWidth
				error={fieldErrors.bannerText?.path === "header"}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={headerChangeMutationFns}
			/>
			<TransparentDivider height="1rem" />

			<TextField
				name="subheading"
				variant="outlined"
				color="secondary"
				label="Sub heading text (optional)"
				multiline
				helperText={
					getErrorMessage("subheading") ?? ofMaxCharactersText(hero.subheading?.length ?? 0, subheadingMaxCharacterCount)
				}
				onChange={(e) => handleOnChange("bannerText")({ ...hero, subheading: e.target.value })}
				value={hero.subheading}
				fullWidth
				error={fieldErrors.bannerText?.path === "subheading"}
				size="medium"
				blurMutationFns={blurMutationFns}
				changeMutationFns={subheadingChangeMutationFns}
				minRows={4}
			/>
		</Stack>
	)
}

import TextField, { trim } from "@/components/TextField"
import { builderValuesAtom } from "@/state"
import { SocialPages } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue } from "jotai"
import React from "react"

export const Socials = () => {
	const { fieldErrors, handleOnChange, values } = useAtomValue(builderValuesAtom)

	const socialMediaDetails = values.socialMediaDetails as SocialPages

	const errorMessage = fieldErrors.socialPages?.message

	type SocialTextFieldProps = {
		name: string
		label: string
		value: string
		onChange: (_value: string) => void
	}
	const trimMutation = trim
	const socialTextField: React.FC<SocialTextFieldProps> = ({ name, label, value, onChange }) => (
		<TextField
			onChange={(e) => onChange(e.target.value)}
			value={value}
			name={name}
			label={label}
			error={fieldErrors.socialPages?.path === name}
			variant="outlined"
			color="secondary"
			fullWidth
			changeMutationFns={[trimMutation]}
			blurMutationFns={[trimMutation]}
			helperText={getErrorMessage(name) ?? helperText[name]}
		/>
	)

	const helperText: Record<string, string> = {
		instagram: "https://www.instagram.com/yourpage",
		facebook: "https://www.facebook.com/yourpage",
		tiktok: "https://www.tiktok.com/@yourpage",
	}
	const fieldHasError = (field: string) => {
		return fieldErrors.socialPages?.path === field
	}

	const getErrorMessage = (field: string): string | undefined => {
		return fieldHasError(field) ? errorMessage : undefined
	}

	return (
		<Stack marginTop={3} marginX={2} gap={3}>
			{socialTextField({
				name: "facebook",
				label: "Facebook URL (optional)",
				value: socialMediaDetails.facebook ?? "",
				onChange: (value) => handleOnChange("socialMediaDetails")({ ...socialMediaDetails, facebook: value }),
			})}
			{socialTextField({
				name: "instagram",
				label: "Instragram URL (optional)",
				value: socialMediaDetails.instagram ?? "",
				onChange: (value) => handleOnChange("socialMediaDetails")({ ...socialMediaDetails, instagram: value }),
			})}
			{socialTextField({
				name: "tiktok",
				label: "TikTok URL (optional)",
				value: socialMediaDetails.tiktok ?? "",
				onChange: (value) => handleOnChange("socialMediaDetails")({ ...socialMediaDetails, tiktok: value }),
			})}
		</Stack>
	)
}

import { AddressComponent as Address, ContactNumber, Flag } from "@/builderComponents"
import TextField, { getMaxCharacterMutationFn, trim } from "@/components/TextField"
import { builderValuesAtom } from "@/state"
import { Contact } from "@/types"
import Stack from "@mui/material/Stack"
import { useAtomValue } from "jotai"
import React from "react"
import { Country } from "react-phone-number-input/input"

export const StoreDetails = () => {
	const { fieldErrors, handleOnChange, values } = useAtomValue(builderValuesAtom)

	const contact = values.storeDetails as Contact

	const getValue = (value?: string): string => value ?? ""

	const fieldHasError = (field: string) => fieldErrors.storeDetails?.path === field

	const errorMessage = fieldErrors.storeDetails?.message

	const onPhoneChange = (phone: string, _flagIcon?: Flag, _countryCode?: string, _country?: string, _ISO?: Country): void => {
		handleOnChange("storeDetails")({ ...contact, phone })
	}

	const onWhatsappChange = (
		whatsapp: string,
		_flagIcon?: Flag,
		_countryCode?: string,
		_country?: string,
		_ISO?: Country,
	): void => {
		handleOnChange("storeDetails")({ ...contact, whatsapp })
	}

	return (
		<Stack marginTop={3} marginX={2} gap="1.5rem">
			<Address
				initialValue={getValue(contact.address)}
				onChange={(address) => handleOnChange("storeDetails")({ ...contact, address })}
				optional
			/>
			<ContactNumber
				value={getValue(contact.phone)}
				label={"Business phone number"}
				validate
				hasErrors={fieldHasError("phone")}
				validationText={errorMessage}
				required
				onChange={onPhoneChange}
			/>
			<ContactNumber
				value={getValue(contact.whatsapp)}
				label={"WhatsApp cellphone number"}
				validate
				required={false}
				hasErrors={fieldHasError("whatsapp")}
				validationText={errorMessage}
				onChange={onWhatsappChange}
			/>
			<TextField
				variant="outlined"
				color="secondary"
				value={getValue(contact.email)}
				required
				label="Business email address"
				helperText={fieldHasError("email") && errorMessage}
				error={fieldHasError("email")}
				changeMutationFns={[getMaxCharacterMutationFn(50), trim]}
				blurMutationFns={[trim]}
				onChange={(e) => handleOnChange("storeDetails")({ ...contact, email: e.target.value })}
			/>
		</Stack>
	)
}

import { TransparentDivider } from "@/components/TransparentDivider"
import { builderWebconfigStateAtom, settingsUIAtom, SettingsUIState } from "@/state"
import { alert } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { ArrowLeft } from "@phosphor-icons/react"
import { useAtom, useSetAtom } from "jotai/index"
import { useState } from "react"
import NudgeCompleteReviewPopup from "./Legal/NudgeCompleteReviewPopup"
import StorePolicyDocumentNav from "./Legal/StorePolicyDocumentNav"

export default function StorePolicy() {
	const setSettingsUI = useSetAtom(settingsUIAtom)
	const [openNudgeDialog, setOpenNudgeDialog] = useState(false)
	const [builderWebconfig] = useAtom(builderWebconfigStateAtom)

	const exitStorePoliciesCheckpoint = () => {
		if (builderWebconfig?.legalWebstorePolicyTnCs?.acceptedAt) {
			setSettingsUI({ uiState: SettingsUIState.None, alert: "" })
		} else {
			setOpenNudgeDialog(true)
		}
	}

	return (
		<>
			<Stack padding="1rem">
				<Button
					sx={{ justifyContent: "flex-start" }}
					color="inherit"
					variant="text"
					startIcon={<ArrowLeft size={18} />}
					onClick={() => exitStorePoliciesCheckpoint()}
				>
					<Typography sx={alert.title} textTransform="none">
						Store policies
					</Typography>
				</Button>
				<TransparentDivider height="1rem" />

				<StorePolicyDocumentNav />
				<TransparentDivider height="1rem" />
			</Stack>
			<NudgeCompleteReviewPopup open={openNudgeDialog} onClose={() => setOpenNudgeDialog(false)} />
		</>
	)
}
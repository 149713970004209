import TextField from "@/components/TextField"
import { useAddressPredictions, useSelectedPlace } from "@/hooks"
import { AutocompleteRenderInputParams } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { useDebounce } from "@uidotdev/usehooks"
import React from "react"

type AddressProps = {
	onChange: (_address: string) => void
	initialValue: string
	optional?: boolean
}

export const AddressComponent: React.FC<AddressProps> = ({ initialValue, onChange, optional }) => {
	const [value, setValue] = React.useState(initialValue)
	const debounced = useDebounce(value, 300)

	const { predictions, addressChange, clear } = useAddressPredictions()
	const getAddress = useSelectedPlace()
	const [disableAutocomplete, setDisableAutocomplete] = React.useState(false)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
		setDisableAutocomplete(false)
	}

	const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
		setDisableAutocomplete(false)
	}

	React.useEffect(() => {
		onChange(value)
	}, [value])

	React.useEffect(() => {
		if (!disableAutocomplete) {
			addressChange(debounced)
		}
	}, [debounced])

	const formatAddress = (addressParts: string[]) =>
		addressParts
			.map((part) => part.trim())
			.filter((part) => part)
			.join(", ")

	const getHandleSelect = (placeId: string) => () => {
		getAddress(placeId, (result) => {
			clear()
			setDisableAutocomplete(true)
			const formattedAddress = formatAddress([result.streetAddress, result.suburb, result.city, result.zipCode])
			setValue(formattedAddress)
		})
	}

	const handleAutocompleteChange = (event: React.SyntheticEvent, newValue: string | null) => {
		if (newValue) {
			const selectedPrediction = predictions.find((prediction) => prediction.description === newValue)
			if (selectedPrediction) {
				getHandleSelect(selectedPrediction.place_id)()
			}
		}
	}

	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			variant="outlined"
			color="secondary"
			{...params}
			label={optional ? "Street address (optional)" : "Street address"}
			onChange={handleChange}
			onClick={handleClick}
		/>
	)

	return (
		<Autocomplete
			value={value}
			options={predictions.map((option) => option.description)}
			inputValue={value}
			onInputChange={(_event, value) => setValue(value)}
			onChange={handleAutocompleteChange}
			renderInput={renderInput}
			freeSolo
		/>
	)
}
